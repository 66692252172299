import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Search } from '../Landing/components/Search/Search';

import './Press.css';

export const Press = () => {
  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Press',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div>
      <div className='hotels-search'>
        <Search />
      </div>
      <div class='press-container'>
        <h1>Press</h1>
        <br/>
        <hr></hr>
        <div className="stories">

        <div id="Story0">
              <br /><br/>
              <div className="storyPic">
                <a href="https://www.topfloorpodcast.com/episode/161" target="_blank">           
                  <img
                    src={require('../../assets/images/press/top-floor-podcast-logo.png')}
                    height='250'
                    alt='Top Floor Podcast Logo'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://www.topfloorpodcast.com/episode/161" target="_blank">Episode 161: Jared Lerner</a></span>
                <br />
                <p>Hospitality veteran Susan Barry gets the scoop from our ZzZEO and delves into Nappr's short-term hotel stay model</p>
                <br/>
                <span className="subTitle">Top Floor</span>
                <p>October 15, 2024</p>
              </div>
            </div>

            <br/><br/>

            <hr/>

        <div id="Story0a">
              <br /><br/>
              <div className="storyPic">
                <a href="https://hospitalitytech.com/start-spotlight-nappr-turns-daytime-napping-business-opportunity-hotels" target="_blank">           
                  <img
                    src={require('../../assets/images/press/hospitality-technology-logo.png')}
                    height='250'
                    alt='Hospitality Technology Logo'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://hospitalitytech.com/start-spotlight-nappr-turns-daytime-napping-business-opportunity-hotels" target="_blank">Start-Up Spotlight:<br/>Nappr Turns Daytime Napping Into a Business Opportunity for Hotels</a></span>
                <br />
                <p>Reporter Michal Christine Escobar talks about Nappr's journey and how we're helping the hospitality industry</p>
                <br/>
                <span className="subTitle">Hospitality Technology</span>
                <p>September 25, 2024</p>
              </div>
            </div>

            <br/><br/>

            <hr/>

        <div id="Story0b">
              <br /><br/>
              <div className="storyPic">
                <a href="https://medium.com/authority-magazine/startup-revolution-jared-lerner-of-nappr-on-how-their-emerging-startup-is-changing-the-game-fad030e08f88" target="_blank">           
                  <img
                    src={require('../../assets/images/press/authority-magazine-logo.png')}
                    height='250'
                    alt='Authority Magazine Logo'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://medium.com/authority-magazine/startup-revolution-jared-lerner-of-nappr-on-how-their-emerging-startup-is-changing-the-game-fad030e08f88" target="_blank">Startup Revolution: Jared Lerner of Nappr On<br/>How Their Emerging Startup is Changing the Game</a></span>
                <br />
                <p>Jared gives an in-depth interview and shares some tips with Tech Reporter Dina Aletras</p>
                <br/>
                <span className="subTitle">Authority Magazine</span>
                <p>August 1, 2024</p>
              </div>
            </div>

            <br/><br/>

            <hr/>

          <div id="Story1">
              <br /><br/>
              <div className="storyPic">
                <a href="https://www.hotelnuggets.com/blog/monetizing-unused-hotel-spaces" target="_blank">           
                  <img
                    src={require('../../assets/images/press/hotel-nuggets-logo.png')}
                    height='250'
                    alt='Hotel Nuggets Logo'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://www.hotelnuggets.com/blog/monetizing-unused-hotel-spaces" target="_blank">Monetizing unused hotel spaces</a></span>
                <br />
                <p>Peter Fabor gives us a shoutout in his hospitality themed bi-weekly newsletter!</p>
                <br/>
                <span className="subTitle">Hotel Nuggets</span>
                <p>July 24, 2024</p>
              </div>
            </div>

            <br/><br/>

            <hr/>

            <div id="Story2">
              <br /><br/>
              <div className="storyPic">
                <a href="https://thetechnational.com/interview-with-jared-lerner-nappr/" target="_blank">           
                  <img
                    src={require('../../assets/images/press/ttn-logo.png')}
                    height='250'
                    alt='Tech National Logo'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://thetechnational.com/interview-with-jared-lerner-nappr/" target="_blank">Interview with Jared Lerner from Nappr: A New Model of Hotels</a></span>
                <br />
                <p>Reporter Lara Dolden interviews our ZzZEO to find out what we're all about</p>
                <br/>
                <span className="subTitle">Tech National</span>
                <p>June 24, 2024</p>
              </div>
            </div>

            <br/><br/>

            <hr/>

            <div id="Story3">
              <br /><br/>
              <div className="storyPic">
                <a href="https://apnews.com/press-release/ez-newswire/hospitality-and-leisure-industry-social-media-cdec4cd7c8cf193b24413f0e740fbeff" target="_blank">           
                  <img
                    src={require('../../assets/images/press/ap-logo.png')}
                    height='250'
                    alt='AP Logo'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://apnews.com/press-release/ez-newswire/hospitality-and-leisure-industry-social-media-cdec4cd7c8cf193b24413f0e740fbeff" target="_blank">Nappr Reaches Milestone with 1,000 Hotels Worldwide</a></span>
                <br />
                <p>A major accomplishment for us!</p>
                <br/>
                <span className="subTitle">Associated Press News</span>
                <p>April 11, 2024</p>
              </div>
            </div>

            <br/><br/>

            <hr/>

            <div id="Story4">
              <br /><br/>
              <div className="storyPic">
                <a href="https://ktla.com/news/travel-sites-to-take-your-trip-to-the-next-level" target="_blank">           
                  <img
                    src={require('../../assets/images/press/ktla-logo.png')}
                    height='250'
                    alt='KTLA News Segment'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://ktla.com/news/travel-sites-to-take-your-trip-to-the-next-level" target="_blank">Travel Sites to take you to the next level</a></span>
                <br />
                <p>Tech Reporter Rich DeMuro shows how Nappr can save you money and take your adventure to the next level!</p>
                <br/>
                <span className="subTitle">KTLA Channel 5 - Los Angeles</span>
                <p>March 20, 2024</p>
              </div>
            </div>

            <br/><br/>

            <hr/>

            <div id="Story5">
              <br /><br/>
              <div className="storyPic">
                <a href="https://hotelbusiness.com/the-benefits-of-short-term-stays-for-hotels/" target="_blank">           
                  <img
                    src={require('../../assets/images/press/hotel-business.png')}
                    height='250'
                    alt='HotelBusiness Article'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://hotelbusiness.com/the-benefits-of-short-term-stays-for-hotels/" target="_blank">The benefits of short-term stays for hotels</a></span>
                <br />
                <p>Our ZzZEO Jared discusses how hotels can maximize their revenues by embracing the concept of 2 to 10-hour microstays</p>
                <br/>
                <span className="subTitle">HotelBusiness.com</span>
                <p>March 13, 2024</p>
              </div>
            </div>

            
            <br/><br/>

            <hr/>

            <div id="Story6">
              <br /><br/>
              <div className="storyPic">
                <a href="https://www.killerstartups.com/get-naps-on-the-go-with-nappr/" target="_blank">           
                  <img
                    src={require('../../assets/images/press/killer-startups.png')}
                    height='250'
                    alt='KillerStartups Logo'
                  />
                </a>
              </div>
              <div className="storyInfo">
                <span className="articleTitle"><a href="https://www.killerstartups.com/get-naps-on-the-go-with-nappr/" target="_blank">Get Naps On-the-go with Nappr</a></span>
                <br />
                <p>Killer Startups Editor-In-Chief Maya Johnson reviews Nappr and showcases what we're all about</p>
                <br/>
                <span className="subTitle">Killer Startups</span>
                <p>October 30, 2023</p>
              </div>
            </div>

        </div>
      </div>
    </div>
  );
};
