import React, { useEffect } from 'react';
import TiktokPixel from 'tiktok-pixel';
import { Header } from './components/Header/Header';
import { Quotes } from './components/Quotes/Quotes';

import './Landing.css';
import { Hotels } from './components/Hotels/Hotels';
import { useNavigate } from 'react-router-dom';
import PopularCities from './components/PopularCities/PopularCities';

export const Landing = () => {
  const navigate = useNavigate();

  useEffect(() => {
    TiktokPixel.track('ViewContent', {
      content_name: 'Landing',
      content_category: 'page',
      timestamp: new Date(),
    });
  }, []);

  return (
    <div>
      <Header />

      <div className="landing-container container">
        <div className="landing-hotels">Hotels</div>
        <h1>Browse our hotels</h1>
        <div className="landing-hotels-view">
          <p style={{ fontSize: '22px', marginTop: '0px' }}>
            Enjoy all the hotel amenities for a fraction of an overnight stay.
          </p>
        </div>

        <div className="landing-hotels-list">
          <Hotels />
        </div>
        <div className="hotels-info-list">
          <div className="hotels-info-content">
            <h1>Why Nappr?</h1>
            <div className="hr" />

            <p style={{ marginTop: '50px', fontSize: '30px' }}>
              We are on a daring mission to get you as much rest and relaxation as possible, because feeling sleep deprived is no fun for anyone.
            </p>

            <div className="" style={{ paddingTop: '60px' }}>
              <Quotes
                icon="hotel"
                title="We offer some of the world's largest hotel brands."
                desc="We've got lots of big-name chains like Marriott, Hilton, Hyatt, Sheraton and Holiday Inn that you can comfortably rely on. But we also have many smaller boutique brands if that's your thing."
              />
              <Quotes
                icon="money"
                title="It's up to 50% cheaper than standard hotel rates."
                desc="Why pay for a full night at a hotel if you only need one for a few hours? Our rates will save you some coin and then some."
              />
              <Quotes
                icon="cities"
                title="We're located in many major cities and airports."
                desc="With over 1,050 hotels in 500 cities across 29 countries, we've got you covered no matter where you go. You can also find our hotels at 50 airports across the world and counting."
              />
              <Quotes
                icon="amenities"
                title="You get all the amenities of an overnight stay."
                desc="Want to rest for a bit but also use the pool and exercise room afterwards? Go right ahead!"
              />
              <Quotes
                icon="nap"
                title="It's a convenient way to take a nap!"
                desc="Napping can improve your sensory perception as effectively as a full night of sleep. It also improves creativity by allowing the brain's right hemisphere to engage in certain key housecleaning tasks and is a well-known mood booster!"
              />
            </div>
          </div>
        </div>

        <div className="landing-apps">
          <div className="landing-apps-container container">
            <div className="landing-apps-intro">
              <h1 style={{ width: '100%' }}>Relaxing stays are a few clicks away</h1>
              <h2 style={{ width: '100%', color:'white' }}>Download our Mobile App today!</h2>
              <div className="landing-apps-buttons">
                <div className="landing-apps-button">
                  <a
                    href="https://apps.apple.com/app/apple-store/id1661113382?pt=118234495&ct=website_footer&mt=8"
                    target="_blank"
                  >
                    <img src={require('../../assets/images/footer/apple-badge.png')} />
                  </a>
                </div>

                <div
                  style={{ marginLeft: '0px' }}
                  className="landing-apps-button"
                >
                  <a id="googleButton"
                    href="https://play.google.com/store/apps/details?id=com.napprmobile&utm_source=website&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                    target="_blank"
                  >
                    <img
                      alt="Get it on Google Play"
                      className="googlePlayButton"
                      src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                      width="250"
                      height="100"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div style={{ width: '40%' }} className="landing-app">
              <img
                src={require('../../assets/images/footer/mobile-app-demo.png')}
                width="400px"
                height="394"
              />
            </div>
          </div>
        </div>

        <div className="AllUseCases">
          <div className="UseCases">
            <h1>Perfect For:</h1>
              <div className="hr" />

              <div className="UseCasesLeft">
                <Quotes
                  icon="airplane"
                  title="Airport Layovers"
                  desc="Beat jet lag the comfortable way"
                />
                <Quotes
                  icon="relax"
                  title="Office Stress"
                  desc="Take a break from TPS Reports"
                />
              </div>
              <div className="UseCasesMid">
                <Quotes
                  icon="student"
                  title="Study Breaks"
                  desc="Help your brain cram for that exam"
                />
                <Quotes
                  icon="hospital"
                  title="Hospital Visits"
                  desc="Stay minutes away from loved ones"
                />
              </div>
              <div className="UseCasesRight">
                <Quotes
                  icon="conference"
                  title="Conferences"
                  desc="Take a meeting away from all the fray"
                />
                <Quotes
                  icon="drive"
                  title="Long Drives"
                  desc="Sleep in a bed, not on the road"
                />
              </div>
          </div>
        </div>
        <div id="PopularCities" className="PopularCities">
          <h1>Popular Cities</h1>
          <div className="hr" />

          <PopularCities />
        </div> 
      </div>

    </div>
  );
};
